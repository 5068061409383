<template>
  <!-- yaowei要闻 dongtai动态 tongzi通知 -->
  <div id="tongzi" class="app">
    <!-- 网站顶部 -->
    <OfficialOtherHead :homePageSetting="homePageSetting" />
    <!-- news -->
    <!-- white标题白色  black标题黑色-->
    <div class="main">
      <!-- 标题 -->
      <div class="content-title">
        <img class="hidden-xs" :src="articleListInfo.bannerImgUrl" />
        <img class="hidden-mx" :src="articleListInfo.bannerImgUrl" />
        <div class="content-box">
          <h1>{{ articleListInfo.title }}</h1>
          <div v-for="(item, index) in articleListInfo.briefList" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
      <!-- 内容 -->
      <div class="panel">
        <!-- 本页导航 -->
        <div class="navbar">
          <a
            v-for="item in articleListInfo.children"
            :key="item.id"
            target="_blank"
            :href="getArticleListUrl(item)"
            :class="[
              'navbar-item',
              item.id === query.articleListId ? 'navbar-item-active' : '',
            ]"
            >{{ item.title }}</a
          >
        </div>
        <!-- 列表 -->
        <div class="listBox">
          <div v-for="item in dataList" :key="item.id" class="listBox-item">
            <a :href="getNewsUrl(item)" class="listBox-link" target="_blank">
              <div class="listBox-item-text">
                <h3 class="listBox-item-title">
                  <div>{{ item.title }}</div>
                </h3>
                <p class="listBox-item-tip">{{ item.summary }}</p>
                <p class="listBox-item-time">{{ item.publishTimeStr }}</p>
              </div>
            </a>
          </div>
        </div>
        <!-- 分页 -->
        <div class="paging">
          <div class="paging-first">首页</div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="query.page"
            :page-size="query.pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
          <div class="paging-last">末页</div>
        </div>
      </div>
    </div>
    <!-- end news -->
    <!-- footer -->
    <!-- 网站底部 -->
    <OfficialFoot :homePageSetting="homePageSetting" />
  </div>
</template>

<script>
import OfficialOtherHead from "./OfficialOtherHead";
import OfficialFoot from "./OfficialFoot";
import { getHomePageSetting, setSeo } from "../api/defaultDataModel";
export default {
  name: "news",
  components: {
    OfficialOtherHead,
    OfficialFoot,
  },
  data: function () {
    return {
      homePageSetting: getHomePageSetting(),
      //总记录数
      total: 0,
      //分页数据
      dataList: [],
      query: {
        page: 1,
        pageSize: 10,
        //当前可分页查询的文章类型id
        articleListId: undefined,
      },
      //文章分类信息
      articleListInfo: {
        id: undefined,
        title: undefined,
        briefList: undefined,
        bannerImgUrl: undefined,
        listType: undefined,
        layoutType: undefined,
        children: undefined,
      },
    };
  },
  methods: {
    onSubmit() {
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
      this.getList();
    },
    getArticleListUrl(articleList) {
      // 企业文化定制
      // if ('1372747014990995456' === articleList.id) {
      //   return `/mien?sid=1427181636147224576&iid=1427181698491359232`
      // } else if ('1372747107848691712' === articleList.id) {
      //   return `/culture?cid=1427181369976692736&lid=1427181425295368192&tid=1427181536322789376`
      // }
      //获取文章分类跳转地址
      //文字列表
      if (1 === articleList.layoutType) {
        return "/plainTextNews?id=" + articleList.id;
      }
      //小图列表
      if (2 === articleList.layoutType) {
        return "/smallPictureNews?id=" + articleList.id;
      }
      //大图列表
      if (3 === articleList.layoutType) {
        return "/bigPictureNews?id=" + articleList.id;
      }
      //竖图列表
      if (4 === articleList.layoutType) {
        return "/verticalPictureNews?id=" + articleList.id;
      }
      return "/";
    },
    getNewsUrl(news) {
      if (news) {
        if (1 === news.articleType) {
          return "/importantDetail?id=" + news.id;
        }
        return "/detail?id=" + news.id;
      }
      return "/";
    },
    getList() {
      //分页获取数据
      this.$http
        .get(this.TzConfig.BASE_URL + "/h5/article/list", {
          params: this.query,
        })
        .then((res) => {
          if (8000 === res.data.code) {
            this.total = res.data.data.totalSize;
            this.dataList = res.data.data.list;
          }
        })
        .catch((err) => {
        });
    },
  },
  created() {
    //当前关于数据
    if (this.$route.query.id && /^\d{15,}$/.test(this.$route.query.id)) {
      this.$http
        .get(this.TzConfig.BASE_URL + "/h5/articleList/getListInfo", {
          params: { id: this.$route.query.id },
        })
        .then((res) => {
          if (8000 === res.data.code) {
            this.query.articleListId = undefined;
            this.articleListInfo = res.data.data;
            if (
              2 !== this.articleListInfo.listType &&
              1 !== this.articleListInfo.listType
            ) {
              this.$router.push({ path: "/" });
            }
            //检查布局类型
            const checkLayoutType = (layoutType, id) => {
              //小图列表
              if (2 === layoutType) {
                this.$router.push({ path: "/smallPictureNews?id=" + id });
                return false;
              }
              //大图列表
              if (3 === layoutType) {
                this.$router.push({ path: "/bigPictureNews?id=" + id });
                return false;
              }
              //竖图列表
              if (4 === layoutType) {
                this.$router.push({ path: "/verticalPictureNews?id=" + id });
                return false;
              }
              return true;
            };
            //当前查询的是双层文章列表
            if (2 === this.articleListInfo.listType) {
              //当前查询的是父级分类
              if (this.$route.query.id === this.articleListInfo.id) {
                //重新查第一个子分类
                if (
                  !checkLayoutType(
                    this.articleListInfo.children[0].layoutType,
                    this.articleListInfo.children[0].id
                  )
                ) {
                  return;
                }
                this.$router.push({
                  path:
                    "/plainTextNews?id=" + this.articleListInfo.children[0].id,
                });
                window.location.reload();
                return;
              }
              //实际查询的子分类
              const articleList = this.articleListInfo.children.filter(
                (itm) => itm.id === this.$route.query.id
              )[0];
              if (!checkLayoutType(articleList.layoutType, articleList.id)) {
                return;
              }
              this.query.articleListId = articleList.id;
            } else if (1 === this.articleListInfo.listType) {
              //单层文章列表
              if (
                !checkLayoutType(
                  this.articleListInfo.layoutType,
                  this.articleListInfo.id
                )
              ) {
                return;
              }
              this.query.articleListId = this.articleListInfo.id;
            }
            //查询分页数据
            if (!this.query.articleListId) {
              this.$router.push({ path: "/" });
              return;
            }
            this.getList();
          } else {
            this.$router.push({ path: "/" });
            return;
          }
        })
        .catch((err) => {     
          this.$router.push({ path: "/" });
        });
    } else {
      this.$router.push({ path: "/" });
    }

    //首页数据
    this.$http
      .get(this.TzConfig.BASE_URL + "/h5/homePageSetting/getSetting")
      .then((res) => {
        if (8000 === res.data.code) {
          this.homePageSetting = res.data.data;
          setSeo(
            this.homePageSetting.seoTitle,
            this.homePageSetting.seoDescription,
            this.homePageSetting.seoKeyword
          );
        }
      })
      .catch((err) => {});
  },
};
</script>
<style scoped>
@charset "utf-8";
#app {
  background-color: #fff;
}
.main {
  margin: 88px auto 0;
  width: 100%;
}
.content-title {
  max-width: 1920px;
  position: relative;
  height: 360px;
  overflow: hidden;
}
.content-title img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hidden-xs {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hidden-mx {
  display: none;
}
.content-box {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  padding: 0 68px;
}
.content-box h1,
.content-box div {
  text-align: center;
  color: #fff;
}
.content-box h1 {
  font-size: 40px;
  line-height: 52px;
}
.content-box h1,
.content-box div
{
  text-align: center;
}
.white .content-box h1,
.white .content-box div
{
  color: #fff;
}
.black .content-box h1,
.black .content-box div
{
  color: #363636;
}
.content-box div {
  margin: 13px 0 0;
  font-size: 16px;
  line-height: 22px;
}
.panel {
  position: relative;
  z-index: 999;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.navbar {
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 38px 0 40px;
  width: 100%;
}
.navbar .navbar-item {
  position: relative;
  display: inline-block;
  margin: 0 30px;
  padding: 0 0 9px;
  vertical-align: top;
  font-size: 18px;
  line-height: 24px;
}
.navbar .navbar-item:after {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: 0 auto;
  content: "";
  width: 100%;
  height: 3px;
  background-color: #fff;
}
.navbar .navbar-item:hover:after,
.navbar .navbar-item-active:after {
  background: #1f5ed1;
}

.listBox,
.listBox-link,
.listBox-item-text {
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
}
.listBox-item-title:hover {
  color: #0b46b1;
}
/* 要闻 */
#yaowei .listBox {
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 1400px;
}
#yaowei .listBox-item {
  position: relative;
  margin: 0 2.28% 32px 0;
  width: calc((100% - 2.28% * 2) / 3);
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(175, 174, 187, 0.16);
}
#yaowei .listBox-item:nth-child(3n) {
  margin: 0 0 32px 0;
}
#yaowei .listBox-link {
  flex-flow: column wrap;
  justify-content: space-between;
}
#yaowei .listBox-link .listBox-item-text {
  flex-flow: column wrap;
  justify-content: space-between;
}
#yaowei .listBox-link .listBox-item-img {
  display: block;
  width: 100%;
  height: 300px;
}
#yaowei .listBox-link .listBox-item-img img {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#yaowei .listBox-item-text .listBox-item-title {
  padding: 18px 18px 0;
  width: 100%;
  height: calc(28px * 2 + 18px);
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*！ autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
#yaowei .listBox-item-text .listBox-item-tip {
  display: none;
  visibility: hidden;
}
#yaowei .listBox-item-text .listBox-item-time {
  padding: 18px 18px 26px;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  color: rgb(29 29 31 / 50%);
}

/* 动态 */
#dongtai .listBox {
  flex-flow: row wrap;
  justify-content: flex-start;
  max-width: 960px;
}
#dongtai .listBox-item {
  position: relative;
  margin: 0 0 42px 0;
  width: 100%;
}
#dongtai .listBox-link {
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
}
#dongtai .listBox-link .listBox-item-img {
  display: block;
  width: 33.33%;
  height: 212px;
}
#dongtai .listBox-link .listBox-item-img img {
  margin: 0 auto;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#dongtai .listBox-item-text {
  flex-flow: column nowrap;
  justify-content: space-between;
  margin: 0 auto 0 0;
  width: 64.16%;
}
#dongtai .listBox-item-text .listBox-item-title {
  margin: 10px 0 0;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*！ autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
#dongtai .listBox-item-text .listBox-item-tip {
  margin: 8px 0 auto;
  font-size: 16px;
  line-height: 28px;
  color: rgb(29 29 31 / 50%);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  /*！ autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
}
#dongtai .listBox-item-text .listBox-item-time {
  margin: 0 0 8px;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: rgb(29 29 31 / 50%);
}

/* 通知 */
#tongzi .listBox {
  flex-flow: row wrap;
  justify-content: space-between;
  max-width: 1120px;
}
#tongzi .listBox-item {
  position: relative;
  margin: 0 auto;
  width: 100%;
  border-bottom: 1px solid #eee;
}
#tongzi .listBox-link {
  flex-flow: row nowrap;
  justify-content: space-between;
}
#tongzi .listBox-link .listBox-item-img,
#tongzi .listBox-link .listBox-item-tip {
  display: none;
  visibility: hidden;
}
#tongzi .listBox-link .listBox-item-text {
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}
#tongzi .listBox-item-text .listBox-item-title {
  display: flex;
  align-items: center;
  margin: 13px 0;
  width: 80%;
  height: 64px;
}
.listBox-item-title div {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /*！ autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  font-size: 20px;
  font-weight: 400;
}
#tongzi .listBox-item-text .listBox-item-time {
  display: -webkit-flex;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  width: 20%;
  font-size: 16px;
  line-height: 24px;
  color: rgba(29, 29, 31, 0.5);
  text-align: right;
  margin-bottom: 0;
}

@media (max-width: 1500px) {
  .listBox,
  #yaowei .listBox,
  #dongtai .listBox {
    max-width: auto;
    width: 90%;
  }
}
@media (max-width: 1024px) {
  #yaowei .listBox-link .listBox-item-img {
    height: 260px;
  }
}

@media (max-width: 990px) {
  .main {
    margin: 64px auto 0;
  }
  .hidden-xs {
    display: none;
  }
  .hidden-mx {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /* .panel {
    padding: 0 0 40px;
  } */
  #yaowei .listBox-item {
    width: calc((100% - 2.28% * 2) / 2);
  }
  #yaowei .listBox-item:nth-child(2n) {
    margin: 0 0 32px 0;
  }
  #yaowei .listBox-link .listBox-item-img {
    height: 300px;
  }
}

@media (max-width: 959px) {
  #dongtai .listBox {
    max-width: auto;
    width: 90%;
  }
  #dongtai .listBox-link .listBox-item-img {
    height: 180px;
  }
  #dongtai .listBox-item-text .listBox-item-tip {
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 768px) {
  #yaowei .listBox-link .listBox-item-img {
    height: 240px;
  }
  #dongtai .listBox-link .listBox-item-img {
    height: 160px;
  }
  #tongzi .listBox-link .listBox-item-text {
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin: 26px 0;
  }
  #tongzi .listBox-item-text .listBox-item-title {
    margin: 0;
    padding: 0;
    width: 76%;
  }
  #tongzi .listBox-item-text .listBox-item-time {
    width: 24%;
  }
}
@media (max-width: 640px) {
  .app {
    background: #f4f6f8;
  }
  .main {
    margin: 1.28rem auto 0;
  }
  .content-title {
    display: none;
    visibility: hidden;
  }
  .content-box h1,
  .content-box div
  {
    text-align: left; 
  }
  .white .content-box h1,
  .white .content-box div,
  .black .content-box h1,
  .black .content-box div
  {
    color: #212121;
  }
  .panel {
    padding: 0;
  }
  .navbar {
    padding: 0;
    margin: 0 0.48rem;
    width: calc(100% - 0.48rem * 2);
  }
  .navbar .navbar-item,
  .navbar .navbar-item-active:after {
    display: none;
  }
  .navbar .navbar-item-active {
    display: block;
    margin: 0;
    padding: 0.4rem 0;
    width: 100%;
    height: auto;
    font-size: 0.64rem;
    line-height: 0.96rem;
    color: #212121;
    text-align: left;
  }
  .listBox,
  #yaowei .listBox,
  #tongzi .listBox {
    padding: 0 0.48rem;
    width: 100%;
  }

  #yaowei .listBox .listBox-item,
  #yaowei .listBox .listBox-item:nth-child(3n),
  #yaowei .listBox .listBox-item:nth-child(2n) {
    margin: 0 0 0.48rem;
    width: 100%;
    box-shadow: 0 0.2rem 0.4rem rgba(175, 174, 187, 0.16);
  }
  #yaowei .listBox-link .listBox-item-img {
    height: 3.6rem;
  }
  #yaowei .listBox-item-text .listBox-item-title {
    padding: 0.34rem 0.26rem 0;
    height: calc(0.5rem * 2 + 0.34rem);
    font-size: 0.36rem;
    line-height: 0.5rem;
  }
  #yaowei .listBox-item-text .listBox-item-time {
    padding: 0.26rem 0.26rem 0.46rem;
    width: 100%;
    font-size: 0.26rem;
    line-height: 0.36rem;
    color: rgb(29 29 31 / 50%);
  }
  .listBox-item-title:hover {
    color: #212121;
  }

  #dongtai .listBox {
    width: 100%;
  }
  #dongtai .listBox-item {
    margin: 0 0 0.64rem 0;
  }
  #dongtai .listBox-link .listBox-item-img {
    display: block;
    width: 34.25%;
    height: 1.48rem;
  }
  #dongtai .listBox-item-text {
    width: calc(100% - 34.25% - 0.24rem);
  }
  #dongtai .listBox-item-text .listBox-item-title {
    margin: 0.1rem 0 0;
    font-size: 0.36rem;
    line-height: 0.44rem;
  }
  #dongtai .listBox-item-text .listBox-item-tip {
    display: none;
    visibility: hidden;
  }
  #dongtai .listBox-item-text .listBox-item-time {
    margin: auto 0 0;
    font-size: 0.24rem;
    line-height: 0.34rem;
  }

  #tongzi .listBox-link {
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin: 0.48rem 0;
  }
  #tongzi .listBox-item:nth-child(1) .listBox-link {
    margin: 0 0 0.48rem;
  }
  #tongzi .listBox-link .listBox-item-text {
    flex-flow: column nowrap;
    justify-content: flex-start;
    margin: 0;
  }
  #tongzi .listBox-item-text .listBox-item-title {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    min-height: calc(0.56rem * 2);
    font-size: 0.36rem;
    line-height: 0.56rem;
  }
  #tongzi .listBox-item-text .listBox-item-time {
    display: inline-block;
    margin: 0.1rem 0 0;
    width: 100%;
    font-size: 0.26rem;
    line-height: 0.36rem;
    text-align: left;
  }
  .paging >>> .el-pagination .btn-next,
  .paging >>> .el-pagination .btn-prev,
  .paging >>> .el-pagination button,
  .el-pagination span:not([class*="suffix"]) {
    background: transparent;
  }
}
</style>
